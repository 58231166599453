<template>
  <div class="main-page">
    <div class="is-top-bar"></div>
    <div class="columns is-centered">
      <div class="column is-2">
        <a href="https://fcsh.unl.pt" target="_blank">
          <img class="is-fcsh-logo" alt="FCSH" src="../assets/fcsh.svg">
        </a>
      </div>
    </div>
    <div class="is-medium-bar"></div>
    <div class="is-center-image">
      <div class="is-main-text">
        <div class="columns is-centered is-button-row">
          <div class="column is-1"></div>
          <div class="column">
            <img class="is-moodle-logo" alt="Moodle" src="../assets/moodle.svg">
            <moodle-button :msg="'Bem-vindos(as) <br/> à plataforma de ensino <br/>digital da NOVA FCSH'" :button-type="'is-small-button'"></moodle-button>
          </div>
          <div class="column is-1"></div>
          <div class="column">
            <div class="is-moodle-logo"></div>
            <a href="https://elearning.fcsh.unl.pt/flv">
              <moodle-button :msg="'Formação <br/> ao longo da vida'" :button-type="'is-large-button'" :clickable="true"></moodle-button>
            </a>
          </div>
          <div class="column is-1"></div>
          <div class="column">
            <div class="is-moodle-logo"></div>
            <a href="https://elearning.fcsh.unl.pt/acient">
              <moodle-button :msg="'Cursos'" :button-type="'is-large-button'" :clickable="true"></moodle-button>
            </a>
          </div>
          <div class="column is-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoodleButton from './MoodleButton.vue'
export default {
  components: { MoodleButton },
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-top-bar {
  margin-top: 0;
  background: #00a490;
  height: 2em;
  width: 100%;
}

.is-fcsh-logo {
  margin-top: 1em;
}

.is-medium-bar {
  background: #0594c4;
  height: 3em;
  width: 100%;
}

.is-center-image {
  background-image: url("../assets/background.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.is-main-text {
  height: 700px;
}

.is-moodle-logo {
  margin-left: 3em;
  margin-bottom: 1em;
  height: 2em;
}

.is-button-row {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
