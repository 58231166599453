<template>
  <div class="fcsh-footer">
    <div class="is-desktop-footer">
      <div class="columns is-vcentered is-moodle-footer" style="">
        <div class="column is-1"></div>
        <div class="column is-3 has-text-left is-footer-beggining">
          <p class="is-uppercase has-text-weight-semibold is-network-text">As nossas redes:</p>
          <a class="network-icon" href="https://www.instagram.com/nova_fcsh/?hl=pt" target="_blank">
            <font-awesome-icon icon="fab fa-instagram" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://twitter.com/nova_fcsh?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
            <font-awesome-icon icon="fab fa-twitter" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://www.facebook.com/NOVAFCSH/" target="_blank">
            <font-awesome-icon icon="fab fa-facebook" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://pt.linkedin.com/school/nova-fcsh/" target="_blank">
            <font-awesome-icon icon="fab fa-linkedin" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://www.youtube.com/user/FCSHUNL" target="_blank">
            <font-awesome-icon icon="fab fa-youtube" size="2x"></font-awesome-icon>
          </a>
        </div>
        <div class="column is-1 is-footer-beggining">
          <p class="is-uppercase has-text-weight-semibold has-text-right has-text-left-mobile is-contacts-text">Contactos:</p>
        </div>
        <div class="column is-2">
          <p class="is-uppercase has-text-weight-semibold has-text-left is-direction-text">Av. de Berna</p>
          <p class="is-direction-text has-text-left">Av. de Berna, 26 C 1069-061 Lisboa</p>
          <p class="is-direction-text has-text-left">(+351) 21 790 83 00 | geral@fcsh.unl.pt</p>
        </div>
        <div class="column is-6">
          <p class="is-uppercase has-text-weight-semibold  has-text-left is-direction-text">Campolide</p>
          <p class="is-direction-text has-text-left">Colégio Almada Negreiros 1099-085 Lisboa</p>
          <p class="is-direction-text has-text-left">(+351) 21 790 83 01 | geral@fcsh.unl.pt</p>
        </div>
      </div>
    </div>
    <div class="is-mobile-footer">
      <div class="columns is-mobile" style="">
        <div class="column is-2-desktop">
          <p class="is-uppercase has-text-weight-semibold has-text-left is-direction-text">Av. de Berna</p>
          <p class="is-direction-text has-text-left">Av. de Berna, 26 C 1069-061 Lisboa</p>
          <p class="is-direction-text has-text-left">(+351) 21 790 83 00 | geral@fcsh.unl.pt</p>
        </div>
        <div class="column is-6-desktop">
          <p class="is-uppercase has-text-weight-semibold  has-text-left is-direction-text">Campolide</p>
          <p class="is-direction-text has-text-left">Colégio Almada Negreiros 1099-085 Lisboa</p>
          <p class="is-direction-text has-text-left">(+351) 21 790 83 01 | geral@fcsh.unl.pt</p>
        </div>
      </div>
      <div class="columns is-mobile is-mobile-icons">
        <div class="column">
          <a class="network-icon" href="https://www.instagram.com/nova_fcsh/?hl=pt" target="_blank">
            <font-awesome-icon icon="fab fa-instagram" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://twitter.com/nova_fcsh?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
            <font-awesome-icon icon="fab fa-twitter" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://www.facebook.com/NOVAFCSH/" target="_blank">
            <font-awesome-icon icon="fab fa-facebook" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://pt.linkedin.com/school/nova-fcsh/" target="_blank">
            <font-awesome-icon icon="fab fa-linkedin" size="2x"></font-awesome-icon>
          </a>
          <a class="network-icon" href="https://www.youtube.com/user/FCSHUNL" target="_blank">
            <font-awesome-icon icon="fab fa-youtube" size="2x"></font-awesome-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/oswald.css';
.fcsh-footer {
  font-family:  'Oswald',Helvetica,Arial,sans-serif;
  background-color: #343a40;
  color: white; 
}

.is-network-text {
  padding-bottom: 1em;
}

.network-icon {
  padding-right: 1em;
  color: white;
}

.is-contacts-text {
  padding-bottom: 3em;
}

.is-footer-beggining {
  margin-left: 3em;
}

.is-moodle-footer {
  padding: 2em 0 2em 0
}

@media screen and (max-width: 700px) {
  .is-footer-beggining {
    margin-left: 0.5em;
  }
  .is-direction-text {
    margin-left: 0.5em;
  }
  .is-desktop-footer{
    display: none;
  }
  .is-mobile-footer {
    padding: 2em;
  }
  .is-mobile-icons {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

@media screen and (min-width: 700px) {
  .is-mobile-footer {
    display: none;
  }
}

</style>